.outer-body {
  max-height: 78vh;
  height: 620px;
  width: 500px;
}
.custom-card {
  padding-bottom: 10px;
  border: 1px solid #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0px 15px rgb(0 0 0 / 10%);
  margin: 0px 0px 20px 0px;
}

.a-link {
  color: #0075f6 !important;
  cursor: pointer;
}

.chat-box-whatsapp {
  display: flex;
  height: 100%;
  background: #efe7dd
    url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
    repeat;
  z-index: 0;
  flex-direction: column;
}

.user-bar {
  flex: 1 1 10%;
  max-height: 55px;
  padding: 5px 0;
  background-color: #005e54;
  align-items: center;
  display: flex;
  color: #fff;
}

.back {
  margin-left: 10px;
  flex: 0 0 7%;
}

.user-avatar {
  flex: 0 0 12%;
  max-width: 34px;
}

.user-avatar img {
  border: 0.5px solid gray;
  border-radius: 50%;
  width: 100%;
}

.img-preview {
  height: 80px;
  width: 100%;
  font-size: 60px;
  color: #c5c5c5;
  background-color: #f5f5f6;
}

.Username {
  margin-left: 10px;
  flex: 1 1 80%;
}

.status {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.conversation {
  flex: 1 1 80%;
  overflow: auto;
}

.message-box {
  background-color: #fff;
  border-radius: 0px 5px 5px;
  color: #000;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  margin: 10px 0px 10px 10px;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}
.wt-buttons {
  text-align: center;
  color: #0092ee;
  padding-top: 5px;
  padding-bottom: 5px;
}

.msg-bar {
  flex: 0 0 10%;
  max-height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.input-box {
  background-color: #fff;
  flex-grow: 1;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.grinEmoticon:before {
  content: "\f581";
}

.send-button {
  height: 40px;
  width: 40px;
  background-color: #008a7c;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#whatsapp-demo-message-button p:first-of-type {
  border-top: 1px solid #eaeaea;
}

#whatsapp-demo-message-header {
  width: 100%;
  font-weight: 600 !important;
  margin-bottom: 3px;
}
#whatsapp-demo-message-footer {
  width: 100%;
  color: #9e9e9e;
  font-size: small;
}
#whatsapp-demo-message-content {
  font: revert;
}
.react-tel-input .special-label {
  top: -6px !important;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 10px !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1;
  left: 10px;
}

.react-tel-input .country-list {
  max-height: 130px;
  margin: 0px 0 10px -1px;
}

.react-tel-input .form-control:focus {
  border-color: #1976d2;
  box-shadow: none !important;
}
.validationError {
  margin: 0;
  font-size: 0.6964285714285714rem;
  margin-top: 3px;
  text-align: left;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
}
.react-tel-input .special-label:focus {
  color: #4076d2;
}
