.chatWindow {
  box-shadow: rgb(0 0 0 / 10%) 0px 12px 24px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}
.whatsappchatHeader {
  color: rgb(17, 17, 17);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 21px 20px 18px 20px;
}
.react-tel-input .special-label {
  top: -6px !important;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 10px !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1;
}
.react-tel-input .form-control:focus {
  border-color: #1976d2;
  box-shadow: none !important;
}
.validationError {
  margin: 0;
  font-size: 0.6964285714285714rem;
  margin-top: 3px;
  text-align: left;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
}
.react-tel-input .special-label:focus {
  color: #4076d2;
}
.footerwhatsappLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.dolphinchatPower {
  animation: 0s ease 0s 1 normal none running none;
  background: rgba(238, 238, 238, 0.9);
  border: none;
  inset: auto auto auto 0px;
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  float: none;
  height: 28px;
  margin: 8px 0px;
  opacity: 1;
  padding: 6px;
  position: relative;
  transform: none;
  zoom: 1;
  visibility: visible;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-decoration: none;
  max-width: 240px;
  text-indent: 0px;
}

.userImage {
  width: 52px;
  height: 52px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.userImageContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.userImageContainer:after {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  inset: 0px;
  position: absolute;
  content: "";
  overflow: hidden;
}
.image {
  min-width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.whatsappchatHeader .headerImage {
  margin-left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
}
.userImage:before {
  content: "";
  bottom: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  background-color: rgb(74, 213, 4);
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  border: 2px solid rgb(9, 94, 84);
}
.headercaptionandtitlediv {
  margin-left: 16px;
  margin-right: 16px;
}
.title {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 20px;
  color: rgb(255, 255, 255);
}
.captionWhatsapp {
  font-size: 12px;
  letter-spacing: 0.2px;
  font-family: sans-serif;
  line-height: 18px;
  margin-top: 4px;
  color: rgb(255, 255, 255);
}
.whatsappchatContainer {
  position: relative;
  padding: 20px 20px 22px 10px;
  background-color: rgb(230, 221, 212);
  overflow: auto;
  max-height: 382px;
  opacity: 1;
}
.whatsappchatContainer:before {
  display: block;
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.08;
  background-image: url(https://static.dolphinchat.ai/plugin/wa/img/whatsapp.png);
}
.messageContainer {
  display: flex;
  z-index: 1;
}
.innerDivMessageContainer {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 1;
  transform-origin: center top;
  z-index: 2;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: 10px;
  max-width: calc(100% - 66px);
  overflow-wrap: break-word;
}
.innerDivMessageContainer:before {
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  top: 0px;
  left: -12px;
  width: 12px;
  height: 19px;
}
.messageDiv {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 0;
  transform-origin: center top;
  z-index: 2;
  box-shadow: rgb(0 0 0 / 13%) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: -54px;
  max-width: calc(100% - 66px);
}
.messageDiv:before {
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  top: 0px;
  left: -12px;
  width: 12px;
  height: 19px;
}
.text {
  font-size: 12px;
  line-height: 19px;
  margin-top: 4px;
  margin-right: 22px;
  color: rgb(17, 17, 17);
  font-family: sans-serif;
}
.name {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  font-family: sans-serif;
  color: rgb(229, 66, 163) !important;
}
.time {
  text-align: right;
  margin-top: 4px;
  font-size: 11px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  margin-right: -8px;
  margin-bottom: -4px;
  font-family: sans-serif;
}
.startChat {
  padding: 6px 12px;
  border-radius: 20px;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-family: sans-serif;
  letter-spacing: 1px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 9px;
  overflow: hidden;
  appearance: none;
  text-decoration: none !important;
}
.whatsappIcon {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  fill: rgb(255, 255, 255);
  z-index: 1;
}
.textStartchat {
  margin-left: 8px;
  margin-right: 8px;
  z-index: 1;
  color: rgb(255, 255, 255);
}
.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  outline: transparent;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 5px 0;
}
.closeIcon:before {
  transform: rotate(45deg);
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  display: block;
  border-radius: 2px;
}
.closeIcon::after {
  transform: rotate(-45deg);
  content: "";
  position: absolute;
  width: 12px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  display: block;
  border-radius: 2px;
}
.whatsappsmartphone {
  position: fixed;
  width: 25%;
  max-width: 300px;
  height: 79vh;
  max-height: 590px;
  margin: auto;
  border: 9px black solid;
  border-top-width: 40px;
  border-bottom-width: 40px;
  border-radius: 36px;
  bottom: 4%;
  padding: 10px 10px 10px 10px;
}

/* The horizontal line on the top of the device */
.whatsappsmartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -19px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.bubble {
  height: 64px;
  box-sizing: unset !important;
  box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
  border-radius: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: absolute !important;
  outline: transparent;
  background-color: rgb(255, 255, 255);
  bottom: 2%;
  width: 64px;
}

.bubble:before {
  content: "";
  position: absolute;
  border: 1px solid rgb(79, 206, 93);
  inset: -2px;
  border-radius: 500px;
  opacity: 0;
  z-index: 0;
}
.bubble:after {
  content: "";
  position: absolute;
  border: 1px solid rgb(79, 206, 93);
  inset: -2px;
  border-radius: 500px;
  opacity: 0;
  z-index: 0;
}
.notification {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 0, 0);
  display: block;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: 4px;
  top: 4px;
}
.icon {
  display: flex;
}
.iconText {
  display: block;
  color: rgb(79, 206, 93);
}
